html, body { background: #CCCCCC; }

body {
    color:#454545;
    font-family:'Open Sans';
    font-size: 1.3rem;
    line-height: 1.3;
    //max-width:800px;
}

a {color:#07A}
a:visited {color:#941352}

hr {
    text-align: center;
    border: 0;
  
    &:before { content: '/////' }
    &:after { content: attr(data-content) '/////' }
}

img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}

code {
    font-size: 1rem;
    color: purple;
    //background: black;
}

div.highlighter-rouge code {
    display: block;
    overflow-x: auto;
    white-space: pre-wrap;
    padding: 1rem;
}

  .w {
    width: fit-content;
    //max-width: 640px;
    max-width: 80ch;
    margin: 0 auto;
    padding: 4rem 2rem;
}

blockquote {
	font-style: italic;
	border: thin solid black;
	padding: 1rem;

	p { margin: 0; }
}
